<template>
    <el-button class="codeBtn" size="small" type="primary" @click="getCode()" :disabled="showGetCode">{{codeText}}</el-button>
</template>

<script>
    export default {
        props: ["mobilePhone"],
        data() {
            return {
                codeText: '获取验证码',
                countdown: 60, // 60秒
                showGetCode: false, // 获取验证码
            }
        },
        mounted() {
            console.log(this.mobilePhone);
        },
        methods: {
            // 倒计时
            setTimeDown() {
                if (this.countdown == 0) {
                    this.showGetCode = false;
                    this.countdown = 60;
                    this.codeText = "获取验证码";
                    return;
                } else {
                    this.showGetCode = true;
                    this.codeText = this.countdown + "s";
                    this.countdown--;
                }
                setTimeout(() => {
                    this.setTimeDown();
                }, 1000);
            },
            // 获取验证码
            getCode() {
                this.$axios(this.api.auth.verifySms, {telNumber: this.mobilePhone}, 'get').then(data => {
                    if (data.status) {
                        this.$message.success('发送成功')
                        this.setTimeDown()
                    } else {
                        this.$message(data.msg)
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
