<template>
    <div id="main" :class="sidebarFold ? 'sidebarFoldShrink' : ''">
        <TopToolbar @showRight="showRight" @showPersonal="showPersonal"/>
        <el-container class="el-container-main">
            <LeftMenu/>
            <CenterMainContent v-if="refreshPage"/>
        </el-container>
        <RightOption ref="mainRight"/>
        <PersonalCenter ref="personalCenter" @showPersonal="showPersonal"/>
    </div>
</template>

<script>
    import TopToolbar from './layout/TopToolbar'
    import LeftMenu from './layout/LeftMenu'
    import CenterMainContent from './layout/CenterMainContent'
    import RightOption from './layout/RightOption'
    import PersonalCenter from './layout/PersonalCenter'

    export default {
        data() {
            return {
                isRightVisible: false,
                isPersonalVisible: false,
                refreshPage: true
            }
        },
        components: {
            TopToolbar,
            LeftMenu,
            CenterMainContent,
            RightOption,
            PersonalCenter
        },
        computed: {
            // 菜单栏缩放
            sidebarFold: {
                get() {
                    return this.$store.state.common.sidebarFold
                }
            }
        },
        watch: {
            // 监听路由
            $route: {
                handler() {
                    this.refresh()
                },
                deep:true,
                immediate: true
            },
        },
        mounted() {
        },
        methods: {
            refresh() {
                this.refreshPage = false
                this.$nextTick(() => (this.refreshPage = true))
            },
            showPersonal(flag) {
                this.$refs.personalCenter.showPersonal(flag);
                this.isPersonalVisible = flag;
            },
            // 显示右边配置
            showRight(flag) {
                this.$refs.mainRight.showRight();
                this.isRightVisible = flag;
            },
        }
    }
</script>
