<template>
  <el-submenu v-if="menu.children && menu.children.length >= 1" :index="menu.id + ''">
    <template slot="title">
      <i :class="`${menu.icon} jp-sidebar__menu-icon`" style=" display: inline-block!important;"></i>
      <span>{{ menu.name }}</span>
    </template>
    <LeftSubmenu v-for="item in menu.children" :key="item.id" :menu="item" :dynamicMenuRoutes="dynamicMenuRoutes"></LeftSubmenu>
  </el-submenu>
  <el-menu-item v-else :index="menu.id + ''" @click="gotoRouteHandle(menu)">
    <i :class="`${menu.icon} jp-sidebar__menu-icon`" style="display: inline-block!important;"></i>
    <span slot="title">{{ menu.name }}</span>
  </el-menu-item>
</template>

<script>
  import LeftSubmenu from './LeftSubmenu'

  export default {
    name: 'LeftSubmenu',
    props: {
      menu: {
        type: Object,
        required: true
      },
      dynamicMenuRoutes: {
        type: Array,
        required: true
      }
    },
    components: {
      LeftSubmenu
    },
    methods: {
      // 路由截取
      translateRouterPath (menu) {
        return '/' + menu.href.replace(/^\//g, '')
      },
      // 通过menuId与动态(菜单)路由进行匹配跳转至指定路由
      gotoRouteHandle (menu) {
        const route = this.dynamicMenuRoutes.filter(item => item.meta.menuId === menu.id)
        if (route.length >= 1) {
          let routePath = this.translateRouterPath(menu)
          if(this.$route.path != routePath) {
            this.$router.push({path: routePath})
          }
        }
      }
    }
  }
</script>
